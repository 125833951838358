<template>
  <div class="px-3">
    <div class="max-width mx-auto py-10 pr-0">
      <v-row justify="center">
        <v-col cols="12" md="3" class="d-flex" style="flex-direction: column">
          <current-plan
            class="mx-auto w-100 h-100"
            :item="freePlan"
            :public-page="true"
          ></current-plan>
        </v-col>
        <template v-for="(item, i) in data">
          <v-col
            cols="12"
            md="3"
            :key="i"
            class="d-flex"
            style="flex-direction: column"
            v-if="item.active"
          >
            <view-plan
              class="mx-auto w-100 h-100"
              :item="item"
              :no-action="true"
            ></view-plan>
          </v-col>
        </template>
      </v-row>
    </div>
    <!-- <div class="text-center my-4">
      <span class="font-20"
        >Or Continue with free trial
        <span class="font-500 primary--text cursor-pointer">Skip</span></span
      >
    </div> -->
  </div>
</template>

<script>
import ViewPlan from "../../components/dialogs/ViewPlan.vue";
import CurrentPlan from "../../components/dialogs/CurrentPlan.vue";
export default {
  components: { ViewPlan, CurrentPlan },
  props: {
    isCurrent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: [],
      freePlan: {},
    };
  },
  methods: {
    async getData() {
      let { data } = await this.$axios.get(process.env.VUE_APP_PUBLIC_PLANS);
      if (data.success) {
        this.data = data.plans.filter((p) => p.title != "FREE Plan");
        this.freePlan = data.plans.find((p) => p.title == "FREE Plan");
      }
    },
  },
  async mounted() {
    await this.getData();
  },
};
</script>

<style></style>
